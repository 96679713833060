// hooks/useRecipePreload.js
import { useCallback, useRef } from 'react';
import { ApiService } from '../services/apiService';
import { createRecipeCacheKey, getCachedData } from '../utils/cacheUtils';

const HOVER_DELAY = 100;
const TOUCH_DELAY = 50;
export const useRecipePreload = () => {
  const hoverTimeoutRef = useRef(null);
  const touchTimeoutRef = useRef(null);
  const isPreloadingRef = useRef(false);

  const preloadRecipe = useCallback(async (recipeId) => {
    if (isPreloadingRef.current) return;

    const cacheKey = createRecipeCacheKey(recipeId);
    const cachedData = getCachedData(cacheKey);

    if (cachedData) {
      console.log('Recipe already cached, skipping preload:', recipeId);
      return;
    }

    try {
      isPreloadingRef.current = true;
      await ApiService.getRecipe(recipeId);
      console.log('Preloaded recipe:', recipeId);
    } catch (error) {
      console.log('Failed to preload recipe:', recipeId, error);
    } finally {
      isPreloadingRef.current = false;
    }
  }, []);

  const clearTimeouts = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    if (touchTimeoutRef.current) {
      clearTimeout(touchTimeoutRef.current);
      touchTimeoutRef.current = null;
    }
  }, []);

  const handleRecipeHover = useCallback((recipeId) => {
    clearTimeouts();

    hoverTimeoutRef.current = setTimeout(() => {
      preloadRecipe(recipeId);
    }, HOVER_DELAY);
  }, [preloadRecipe, clearTimeouts]);

  const handleRecipeLeave = useCallback(() => {
    clearTimeouts();
  }, [clearTimeouts]);

  const handleRecipeTouch = useCallback((recipeId) => {
    clearTimeouts();

    touchTimeoutRef.current = setTimeout(() => {
      preloadRecipe(recipeId);
    }, TOUCH_DELAY);
  }, [preloadRecipe, clearTimeouts]);

  const handleRecipeContextMenu = useCallback((recipeId) => {
    preloadRecipe(recipeId);
  }, [preloadRecipe]);

  return {
    onRecipeHover: handleRecipeHover,
    onRecipeLeave: handleRecipeLeave,
    onRecipeTouch: handleRecipeTouch,
    onRecipeContextMenu: handleRecipeContextMenu
  };
};