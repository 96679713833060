import React, {useMemo} from "react";
import { useSelector } from "react-redux"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './RecipeSlider.module.css';
import { Link } from "react-router-dom";
import { useRecipePreload } from "../../hooks/useRecipePreload";
import { languageMapping } from "../../utils/languageMapping";
import ArrowRight from "../Slider/ArrowRight";
import ArrowLeft from "../Slider/ArrowLeft";


// Simplified recipe card that focuses on essential information
const SliderRecipeCard = React.memo(({ recipe, SB_recipeMultilingueFields, enablePreload = true }) => {
  const { fields, language } = useSelector((state) => state.app);
  const languageIndex = languageMapping(language);

  const {
    onRecipeHover,
    onRecipeLeave,
    onRecipeTouch,
    onRecipeContextMenu
  } = useRecipePreload();

  const getMacroValue = useMemo(() => (macro) => {
    try {
      if (!fields || typeof fields !== 'object') {
        return '0.0';
      }

      const fieldEntry = Object.entries(fields)
        .find((field) => field[1]?.display === macro);

      if (!fieldEntry) {
        return '0.0';
      }

      const fieldId = +fieldEntry[0];

      const macroQuantity = recipe?.quantityPerPerson
        ?.find((quantity) => quantity?.fieldId === fieldId);

      return macroQuantity?.value?.toFixed(1) ?? '0.0';
    } catch (error) {
      console.error('Error calculating macro value:', error);
      return '0.0';
    }
  }, [recipe?.quantityPerPerson, fields]);

  return (
    <div style={{
      textAlign: "center",
      margin: "10px 0",
      position: "relative",
    }}
       onMouseEnter={() => enablePreload && onRecipeHover(recipe.id)}
       onMouseLeave={() => enablePreload && onRecipeLeave()}
       onTouchStart={() => enablePreload && onRecipeTouch(recipe.id)}
       onContextMenu={(e) => {
         if (enablePreload) {
           onRecipeContextMenu(recipe.id);
         }
       }}
    >
      <Link
        to={{
          pathname: `/vegetarian-food/detail/${recipe.id}`,
        }}
        className={styles.recipeLink}
      >
        {/* Image Section */}
        <div style={{position: "relative"}}>

          <div
            style={{
              backgroundImage: `url(${recipe.image})`,
              width: "100%",
              paddingTop: "54.4%",
              backgroundSize: "100% 100%",
              borderRadius: "8px 8px 0px 0px",
            }}
          />

        </div>

        {/* Content Section */}
        <div style={{
          padding: "5px 10% 0 10%",
          borderRadius: "0 0 28px 28px",
          boxShadow: "0 8px 10px rgb(0 0 0 / 20%)",
          backgroundColor: "white",
        }}>
          <p style={{
            fontSize: 16,
            color: "black",
            fontWeight: 400,
            textAlign: "center",
            fontFamily: "Roboto",
            display: "inline-block",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
            {recipe.title[languageIndex]}
          </p>

          <div className={styles.nutritionContainer}>
            <div className={styles.nutritionItem}>
            <span className={styles.nutritionLabel}>
              {SB_recipeMultilingueFields?.content?.Calorie}
            </span>
              <span className={styles.nutritionValue}>
              {getMacroValue("Energy")} g
            </span>
            </div>
            <div className={styles.nutritionItem}>
            <span className={styles.nutritionLabel}>
              {SB_recipeMultilingueFields?.content?.Protein}
            </span>
              <span className={styles.nutritionValue}>
              {getMacroValue("Protein")} g
            </span>
            </div>
            <div className={styles.nutritionItem}>
            <span className={styles.nutritionLabel}>
              {SB_recipeMultilingueFields?.content?.Fat}
            </span>
              <span className={styles.nutritionValue}>
              {getMacroValue("Fat")} g
            </span>
            </div>
          </div>
        </div>
      </Link>

    </div>
  );
});

// Main slider component with optimized settings for recipe display
const RecipeSlider = ({recipes, SB_recipeMultilingueFields}) => {
  const sliderSettings = {
    dots: false,                    // Removed dots for cleaner look
    infinite: recipes.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <ArrowRight space={-40} />,
    prevArrow: <ArrowLeft space={-40} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: recipes.length > 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: recipes.length > 1
        }
      }
    ]
  };

  if (!recipes?.length) {
    return null;
  }

  return (
    <div className={styles.sliderContainer}>
      <Slider {...sliderSettings}>
        {recipes.map((recipe, index) => (
          <div key={recipe.id || index} className={styles.slideWrapper}>
            <SliderRecipeCard
              recipe={recipe}
              SB_recipeMultilingueFields={SB_recipeMultilingueFields}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RecipeSlider;