import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StoryblokClient from "storyblok-js-client";
import {
  createStoryblokCacheKey,
  getCachedStory,
  setCachedStory,
  shouldRefreshCache
} from '../utils/storyblokCache';

const Storyblok = new StoryblokClient({
  accessToken: "Z621f1nlepsmJNp4sYMPEgtt",
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export function useStoryblok(slug, filterQuery) {
  const [story, setStory] = useState(null);
  const language = useSelector((state) => state.app.language);
  const filterQueryStr = JSON.stringify(filterQuery);

  useEffect(() => {
    let params = {
      version: "published",
      language: language?.toLowerCase(),
      fallback_lang: "en",
    };

    const cacheKey = createStoryblokCacheKey(slug, language, filterQuery);

    const cachedStory = getCachedStory(cacheKey);
    if (cachedStory) {
      console.log('Using cached Storyblok content for:', slug || filterQuery);
      setStory(cachedStory);

      if (shouldRefreshCache(cacheKey)) {
        fetchAndCacheStory();
      }
      return;
    }

    async function fetchAndCacheStory() {
      try {
        if (slug) {
          const response = await Storyblok.get(`cdn/stories/${slug}`, {
            ...params,
          });
          const storyData = response.data.story;
          setStory(storyData);
          setCachedStory(cacheKey, storyData);
        } else if (filterQuery) {
          let modifiedFilterQuery = { ...filterQuery };
          if (modifiedFilterQuery.sort_by) {
            modifiedFilterQuery.sort_by = changeLanguageCode(
              modifiedFilterQuery.sort_by,
              language
            );
          }

          const response = await Storyblok.get('cdn/stories', {
            ...params,
            ...modifiedFilterQuery,
          });

          const storyData = {
            stories: response.data.stories,
            pages: Math.ceil(response.total / response.perPage),
          };
          setStory(storyData);
          setCachedStory(cacheKey, storyData);
        }
      } catch (error) {
        console.error('Error fetching Storyblok content:', error);
      }
    }

    fetchAndCacheStory();
  }, [slug, language, filterQueryStr]);

  return story;
}

function changeLanguageCode(inputString, languageCode) {
  const pattern = /(__i18n__)(\w{2})/;
  const replacement = "$1" + languageCode;
  return inputString.replace(pattern, replacement);
}