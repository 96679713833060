import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FaBookmark } from "react-icons/fa";
import { FiBookmark } from "react-icons/fi";
import { TbCalendarTime } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import { useRecipePreload} from "../../hooks/useRecipePreload";
import { updatePrefer, addWeeklyPlan } from "../../store/app";
import FoodImage1 from "../../assets/image/tmp/food-1.png";
import VotingMealDialog from "../VotingMealDialog";
import { languageMapping } from "../../utils/languageMapping";
import WeeklyPlanDialog from "../WeeklyPlanDialog";
import { ApiService} from "../../services/apiService";


const RecipeItem = ({
  recipe,
  SB_recipeMultilingueFields,
  showFavIcon = false,
  clickVoting = false,
  enablePreload = true
}) => {
  const {
    onRecipeHover,
    onRecipeLeave,
    onRecipeTouch,
    onRecipeContextMenu
  } = useRecipePreload();
  const dispatch = useDispatch();

  const { fields, language, weeklyPlans } = useSelector((state) => state.app);
  const voteInfo = recipe.votes.comments.find((vote) => vote.isOwn);

  const [preference, setPreference] = useState(recipe.preferred);
  const [openVoting, setOpenVoting] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [planOpen, setPlanOpen] = useState(false);
  const [mealTime, setMealTime] = useState({});
  const [addedMeal, setAddedMeal] = useState(false);

  const languageIndex = languageMapping(language);

  useEffect(() => {
    setPreference(recipe.preferred);
  }, [recipe]);

  useEffect(() => {
    if (weeklyPlans) {
      const mealId = weeklyPlans.find((ele) => {
        return ele.meals[0].id === recipe.id;
      });
      setAddedMeal(!!mealId);
    }
  }, [weeklyPlans, recipe.id]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        if (planOpen) handleClosePlan();
        if (openVoting) handleClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [planOpen, openVoting]);

  const handlePreference = () => {
    setPreference(!preference);
    dispatch(updatePrefer(recipe.id, !preference));
  };

  const handleVoting = () => {
    setOpenVoting(true);
  };

  const handleClose = () => {
    setOpenVoting(false);
  };

  const handleWeeklyPlan = () => {
    setPlanOpen(true);
  };

  const handleApplyPlan = () => {
    if (Object.keys(mealTime).length === 0) return;

    dispatch(addWeeklyPlan(recipe.id, mealTime.date, mealTime.time, 1));

    setMealTime({});
    setPlanOpen(false);
  };

  const handleClosePlan = () => {
    setMealTime({});
    setPlanOpen(false);
  };

  const getMacroValue = useMemo(() => (macro) => {
    return recipe?.quantityPerPerson
      ?.find(
        (quantity) =>
          quantity.fieldId ===
          +Object.entries(fields).find((field) => field[1].display === macro)[0]
      )
      .value.toFixed(1);
  }, [recipe?.quantityPerPerson, fields]);

  if (!fields) {
    return;
  }

  return (
    <>
      <div
        style={{
          textAlign: "center",
          margin: "10px 0",
          position: "relative",
        }}

        onMouseEnter={() => enablePreload && onRecipeHover(recipe.id)}
        onMouseLeave={() => enablePreload && onRecipeLeave()}
        onTouchStart={() => enablePreload && onRecipeTouch(recipe.id)}
        onContextMenu={(e) => {
          if (enablePreload) {
            onRecipeContextMenu(recipe.id);
          }
        }}
      >
        <div style={{ position: "relative" }}>
          <Link
            to={{
              pathname: `/vegetarian-food/detail/${recipe.id}`,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${recipe.image ?? FoodImage1})`,
                width: "100%",
                paddingTop: "54.4%",
                backgroundSize: "100% 100%",
                borderRadius: "8px 8px 0px 0px",
              }}
            />
          </Link>
          <div
            className="pointer"
            onClick={clickVoting ? handleVoting : undefined}
            style={{
              backgroundColor: "white",
              borderRadius: "15px",
              position: "absolute",
              bottom: "5px",
              right: "10px",
              display: "flex",
              alignItems: "center",
              gap: "3px",
              padding: "1px 5px",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                color: "#6B6C72",
                fontWeight: "400",
              }}
            >
              {recipe?.votes?.voteStar}
            </p>
            {voteInfo ? (
              <AiFillStar
                className="pointer"
                style={{
                  fontSize: 11,
                  color: "#FAA72D",
                }}
              />
            ) : (
              <AiOutlineStar
                className="pointer"
                style={{
                  fontSize: 11,
                  color: "#FAA72D",
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            padding: "5px 10% 0 10%",
            borderRadius: "0 0 28px 28px",
            boxShadow: "0 8px 10px rgb(0 0 0 / 20%)",
            backgroundColor: "white",
          }}
        >
          <Tooltip
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            disableInteractive
            title={recipe.title[languageIndex]}
            placement="top"
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <p
                onMouseOver={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                style={{
                  fontSize: 16,
                  color: "black",
                  fontWeight: 400,
                  textAlign: "center",
                  fontFamily: "Roboto",
                  display: "inline-block",
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {recipe.title[languageIndex]}
              </p>
              {showFavIcon && (
                <div style={{
                  display: "flex",
                  gap: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: "5px"
                }}>
                  <div
                    className="pointer"
                    onClick={handleWeeklyPlan}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TbCalendarTime
                      style={{
                        fontSize: "22px",
                        color: addedMeal ? "#FC9C52" : "#6B6C72",
                      }}
                    />
                  </div>
                  {preference ? (
                    <FaBookmark
                      className="pointer"
                      style={{ fontSize: 22, color: "orange" }}
                      onClick={handlePreference}
                    />
                  ) : (
                    <FiBookmark
                      className="pointer"
                      style={{ fontSize: 22, color: "orange" }}
                      onClick={handlePreference}
                    />
                  )}
                </div>
              )}
            </div>
          </Tooltip>
          {/* <div style={{ textAlign: "left" }}>
            <p
              style={{
                fontSize: 16,
                color: "black",
                fontWeight: 400,
                textAlign: "left",
                fontFamily: "Roboto",
                display: "inline-block",
                width: "80%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {recipe.title[language]}
            </p>
            {preference ? (
              <FaBookmark
                className="pointer"
                style={{ fontSize: 22, color: "orange", float: "right" }}
                onClick={handlePreference}
              />
            ) : (
              <FiBookmark
                className="pointer"
                style={{ fontSize: 22, color: "orange", float: "right" }}
                onClick={handlePreference}
              />
            )}
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
              color: "black",
              paddingBottom: "10%",
              paddingTop: "5%",
            }}
          >
            <div>
              <p
                style={{
                  color: "rgb(249, 147, 69)",
                  fontSize: 12,
                  fontWeight: 700,
                  marginBottom: 0,
                  fontFamily: "Roboto",
                }}
              >
                {SB_recipeMultilingueFields?.content?.Calorie}
              </p>
              <p
                className="text-center"
                style={{ fontSize: 12, color: "#6B6C72", fontWeight: 600 }}
              >
                {getMacroValue("Energy")}
              </p>
            </div>
            <div>
              <p
                style={{
                  color: "rgb(249, 147, 69)",
                  fontSize: 12,
                  fontWeight: 700,
                  marginBottom: 0,
                  fontFamily: "Roboto",
                }}
              >
                {SB_recipeMultilingueFields?.content?.Protein}
              </p>
              <p
                className="text-center"
                style={{ fontSize: 12, color: "#6B6C72", fontWeight: 600 }}
              >
                {getMacroValue("Protein")}
              </p>
            </div>
            <div>
              <p
                style={{
                  color: "rgb(249, 147, 69)",
                  fontSize: 12,
                  fontWeight: 700,
                  marginBottom: 0,
                  fontFamily: "Roboto",
                }}
              >
                {SB_recipeMultilingueFields?.content?.Fat}
              </p>
              <p
                className="text-center"
                style={{ fontSize: 12, color: "#6B6C72", fontWeight: 600 }}
              >
                {getMacroValue("Fat")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <VotingMealDialog
        openDialog={openVoting}
        onCloseHandler={() => handleClose()}
        title={SB_recipeMultilingueFields?.content?.Vote_Title}
        recipe={recipe}
      ></VotingMealDialog>

      <WeeklyPlanDialog
        open={planOpen}
        onClose={handleClosePlan}
        onApply={handleApplyPlan}
        selectedTime={mealTime}
        onTimeChange={(date, mealTime) => setMealTime({ date, time: mealTime })}
      />
    </>
  );
};

export default RecipeItem;
