// utils/cacheUtils.js

// Cache durations for different types of data
export const CACHE_DURATIONS = {
  STATIC: 24 * 60 * 60 * 1000,  // 24 hours
  MODERATE: 60 * 60 * 1000,     // 1 hour
  DYNAMIC: 60 * 1000,           // 1 minute
  RECIPE: 5 * 60 * 1000         // 5 minutes (maintaining existing recipe cache duration)
};

// Cache key prefixes - including existing ones
export const CACHE_PREFIXES = {
  RECIPE: 'recipe_cache',        // Maintaining existing recipe prefix
  SEARCH: 'search_cache',        // Maintaining existing search prefix
  LANGUAGES: 'lang_',
  FIELDS: 'field_',
  INGREDIENTS: 'ingr_',
  RDD: 'rdd_',
  CALC_TYPES: 'calc_',
  DIFFICULTY: 'diff_',
  CUISINES: 'cuisine_',
  VEG_TYPES: 'veg_',
  COUNTRIES: 'country_',
  CONTENTS: 'content_',
  PREFERS: 'prefer_',
  WEEKLY: 'weekly_',
  GROCERY: 'grocery_',
  MEAL_LEVELS: 'meal_level_',
  RECOMMENDATIONS: 'recom_'
};

// Maintaining existing recipe-specific functions for backward compatibility
export const createRecipeCacheKey = (recipeId) => {
  return `${CACHE_PREFIXES.RECIPE}${recipeId}`;
};

export const createSearchCacheKey = (page, count, sortBy, filter) => {
  return `${CACHE_PREFIXES.SEARCH}${JSON.stringify({
    page,
    count,
    sortBy,
    filter: filter || {},
  })}`;
};

// Enhanced generic cache key creation for new endpoints
export const createCacheKey = (prefix, params = {}) => {
  const sortedParams = Object.entries(params)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  return `${prefix}${JSON.stringify(sortedParams)}`;
};

// Enhanced getCachedData that supports different durations
export const getCachedData = (key) => {
  try {
    const cached = localStorage.getItem(key);
    if (!cached) return null;

    const { data, timestamp, duration } = JSON.parse(cached);

    // Use specific duration if provided, fall back to recipe duration for backward compatibility
    const expiryDuration = duration || CACHE_DURATIONS.RECIPE;

    // Check if cache has expired based on its specific duration
    if (Date.now() - timestamp > expiryDuration) {
      localStorage.removeItem(key);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Cache retrieval error:', error);
    return null;
  }
};

// Enhanced setCacheData that supports different durations
export const setCacheData = (key, data, duration = CACHE_DURATIONS.RECIPE) => {
  try {
    const cacheEntry = {
      data,
      timestamp: Date.now(),
      duration  // Store the duration with the cache entry
    };
    localStorage.setItem(key, JSON.stringify(cacheEntry));
  } catch (error) {
    console.error('Cache setting error:', error);
    clearOldCache();
  }
};

// Enhanced clearOldCache that handles all cache types
const clearOldCache = () => {
  try {
    const allKeys = Object.keys(localStorage);
    const cacheKeys = allKeys.filter(key =>
      Object.values(CACHE_PREFIXES).some(prefix => key.startsWith(prefix))
    );

    // Sort by timestamp and remove oldest entries
    const sortedKeys = cacheKeys.sort((a, b) => {
      const aTime = JSON.parse(localStorage.getItem(a)).timestamp;
      const bTime = JSON.parse(localStorage.getItem(b)).timestamp;
      return bTime - aTime;
    });

    // Remove the oldest 50% of entries
    sortedKeys.slice(Math.floor(sortedKeys.length / 2)).forEach(key => {
      localStorage.removeItem(key);
    });
  } catch (error) {
    console.error('Cache clearing error:', error);
    localStorage.clear();
  }
};

// New helper function for caching API calls
export const withCache = async (
  cacheKey,
  fetchFunction,
  duration = CACHE_DURATIONS.MODERATE,
  forceRefresh = false
) => {
  // Check cache if not forcing refresh
  if (!forceRefresh) {
    const cachedData = getCachedData(cacheKey);
    if (cachedData) {
      console.log('Cache hit:', cacheKey);
      return cachedData;
    }
  }

  // Fetch fresh data
  console.log('Cache miss or refresh:', cacheKey);
  const data = await fetchFunction();

  // Store in cache with specified duration
  setCacheData(cacheKey, data, duration);

  return data;
};