const CACHE_EXPIRY_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds
const STORYBLOK_CACHE_KEY = 'storyblok_cache';

export const createStoryblokCacheKey = (slug, language, filterQuery) => {
  return JSON.stringify({
    slug: slug || null,
    language: language?.toLowerCase(),
    filterQuery: filterQuery || null
  });
};

// Get cached story data
export const getCachedStory = (cacheKey) => {
  try {
    const cachedData = localStorage.getItem(`${STORYBLOK_CACHE_KEY}:${cacheKey}`);
    if (!cachedData) return null;

    const { data, timestamp } = JSON.parse(cachedData);

    if (Date.now() - timestamp > CACHE_EXPIRY_TIME) {
      localStorage.removeItem(`${STORYBLOK_CACHE_KEY}:${cacheKey}`);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Storyblok cache retrieval error:', error);
    return null;
  }
};

export const setCachedStory = (cacheKey, data) => {
  try {
    const cacheEntry = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(
      `${STORYBLOK_CACHE_KEY}:${cacheKey}`,
      JSON.stringify(cacheEntry)
    );
  } catch (error) {
    console.error('Storyblok cache setting error:', error);
    clearOldStoryblokCache();
  }
};

const clearOldStoryblokCache = () => {
  try {
    const allKeys = Object.keys(localStorage);
    const storyblokKeys = allKeys.filter(key =>
      key.startsWith(STORYBLOK_CACHE_KEY)
    );

    const sortedKeys = storyblokKeys.sort((a, b) => {
      const aTime = JSON.parse(localStorage.getItem(a)).timestamp;
      const bTime = JSON.parse(localStorage.getItem(b)).timestamp;
      return bTime - aTime;
    });

    sortedKeys.slice(Math.floor(sortedKeys.length / 2)).forEach(key => {
      localStorage.removeItem(key);
    });
  } catch (error) {
    console.error('Storyblok cache clearing error:', error);
    Object.keys(localStorage)
      .filter(key => key.startsWith(STORYBLOK_CACHE_KEY))
      .forEach(key => localStorage.removeItem(key));
  }
};

// Helper to determine if we should refresh cache
export const shouldRefreshCache = (cacheKey) => {
  try {
    const cachedData = localStorage.getItem(`${STORYBLOK_CACHE_KEY}:${cacheKey}`);
    if (!cachedData) return true;

    const { timestamp } = JSON.parse(cachedData);
    return Date.now() - timestamp > CACHE_EXPIRY_TIME;
  } catch {
    return true;
  }
};