import { config } from "../config";
import { API, Auth } from "aws-amplify";
import store from "../store";
import {
  createSearchCacheKey,
  createRecipeCacheKey,
  getCachedData,
  setCacheData,
  createCacheKey,
  CACHE_PREFIXES,
  CACHE_DURATIONS,
  withCache
} from '../utils/cacheUtils';

function getLanguage() {
  console.log('Retrieving languages from store');
  const defaultLanguageCode = 1;
  const languages = store.getState().app.languages;
  const language = store.getState().app.language;
  if (!languages){
    console.log('No language list found in store, selected default language');
    return defaultLanguageCode;
  }
  if (!language) {
    console.log('No specific language found in store');
    return (
      Object.keys(languages)?.find?.(
        (l) =>
          languages[l].code.toLowerCase() ===
          (window.navigator.language || window.navigator.userLanguage)
      ) ?? defaultLanguageCode
    );
  }
  console.log('Specific language found in store');
  return (
    Object.keys(languages)?.find?.(
      (l) => languages[l].code === language.toUpperCase()
    ) ?? defaultLanguageCode
  );
}

async function getLanguages() {
  console.log('Getting languages from API');
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(config.API_URL + "languages/list", options);
    return response.json();
  } else {
    const apiName = "dev-language-api";
    const path = "/languages/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getMealLevels() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + `/meal-levels/list?language=${getLanguage()}`,
      options
    );
    return response.json();
  } else {
    const apiName = "dev-meal-level-api";
    const path = `/meal-levels/list?language=${getLanguage()}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };
    return API.get(apiName, path, init);
  }
}

async function getFields() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(config.API_URL + "fields/list", options);
    return response.json();
  } else {
    const apiName = "dev-field-api";
    const path = "/fields/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getIngredients(page, count, sortField, sortBy, keyword) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: keyword,
      }),
    };

    const response = await fetch(config.API_URL + "ingredients/list", options);
    return response.json();
  } else {
    const apiName = "dev-ingredient-api";
    const path = "/ingredients/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: keyword,
      },
    };

    return API.post(apiName, path, init);
  }
}

async function getIngredient(code) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + "ingredients/" + code,
      options
    );
    return response.json();
  } else {
    const apiName = "dev-ingredient-api";
    const path = `/ingredients/${code}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getRDDs(page, count, sortField, sortBy, filter) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      }),
    };

    const response = await fetch(config.API_URL + "rdds/list", options);
    return response.json();
  } else {
    const apiName = "dev-rdd-api";
    const path = "/rdds/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      },
    };

    return API.post(apiName, path, init);
  }
}

async function getRDDEvaluations() {
  let jwtToken = store.getState().session.user?.token;
	try { jwtToken = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken; } catch(_) {}
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': jwtToken,
      }
    };

    const response = await fetch(config.API_URL + 'rdds/evals/list', options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = '/rdds/evals/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getRDD(code, type) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        calcTypeId: type,
      }),
    };

    const response = await fetch(config.API_URL + "rdds/" + code, options);
    return response.json();
  } else {
    const apiName = "dev-rdd-api";
    const path = `/rdds/${code}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        calcTypeId: type,
      },
    };

    return API.post(apiName, path, init);
  }
}
let promise;
async function getRecipes(page, count, sortBy, filter) {
  API.cancel(promise);

  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        order: sortBy,
        ...filter,
      }),
    };

    const response = await fetch(config.API_URL + "meals/list", options);
    return response.json();
  } else {
    const apiName = "dev-meal-api";
    const path = "/meals/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        order: sortBy,
        ...filter,
      },
    };
    promise = API.post(apiName, path, init);
    return promise;
  }
}

// Create wrapped versions of the original functions
const getRecipesWithCache = async (page, count, sortBy, filter) => {
  // Generate cache key for this specific search
  const cacheKey = createSearchCacheKey(page, count, sortBy, filter);

  // Check cache first
  const cachedData = getCachedData(cacheKey);
  if (cachedData) {
    console.log('Returning cached search results');
    return cachedData;
  }

  // If not in cache, make the API call
  const results = await getRecipes(page, count, sortBy, filter);

  // Store in cache
  setCacheData(cacheKey, results);

  return results;
};

async function getRecipe(code) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(config.API_URL + "meals/" + code, options);
    return response.json();
  } else {
    const apiName = "dev-meal-api";
    const path = `/meals/${code}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

const getRecipeWithCache = async (code) => {
  // Generate cache key for this recipe
  const cacheKey = createRecipeCacheKey(code);

  // Check cache first
  const cachedData = getCachedData(cacheKey);
  if (cachedData) {
    console.log('Returning cached recipe details');
    return cachedData;
  }

  // If not in cache, make the API call
  const results = await getRecipe(code);

  // Store in cache
  setCacheData(cacheKey, results);

  return results;
};

async function getRecommendations() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + "meals/recommendations",
      options
    );
    return response.json();
  } else {
    const apiName = "dev-meal-api";
    const path = "/meals/recommendations" + (!jwtToken ? "/noauth" : "");
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getCalcTypes() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + "calculation-types/list",
      options
    );
    return response.json();
  } else {
    const apiName = "dev-calculation-type-api";
    const path = "/calculation-types/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getDifficultyLevels() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + `/difficulty-levels/list?language=${getLanguage()}`,
      options
    );
    return response.json();
  } else {
    const apiName = "dev-difficulty-level-api";
    const path = `/difficulty-levels/list?language=${getLanguage()}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getCuisines() {
  let jwtToken = store.getState().session.user?.token;
  const language = getLanguage();
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + `/cuisines/list?language=${language}&existing=true`,
      options
    );
    return response.json();
  } else {
    const apiName = "dev-cuisine-api";
    const path = `/cuisines/list?language=${language}&existing=true`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getVegetarianTypes() {
  let jwtToken = store.getState().session.user?.token;
  const language = getLanguage();
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + `/vegetarian-types/list?language=${language}`,
      options
    );
    return response.json();
  } else {
    const apiName = "dev-vegetariantype-api";
    const path = `/vegetarian-types/list?language=${language}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getCountries() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(config.API_URL + "countries/list", options);
    return response.json();
  } else {
    const apiName = "dev-country-api";
    const path = "/countries/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getContents() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + "global_params/contents",
      options
    );
    return response.json();
  } else {
    const apiName = "dev-global-params-api";
    const path = `/global_params/contents`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

let promisePrefers;
async function getPrefers(page, count, sortBy, filter) {
  API.cancel(promisePrefers);
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        order: sortBy,
        ...filter,
      }),
    };

    const response = await fetch(config.API_URL + "meals/prefers", options);
    return response.json();
  } else {
    const apiName = "dev-meal-api";
    const path = `/meals/prefers`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        page: page,
        rowCount: count,
        order: sortBy,
        ...filter,
      },
    };
    promisePrefers = API.post(apiName, path, init);
    return promisePrefers;
  }
}

async function deleteMyList() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(config.API_URL + "meals/prefers", options);
    return response.json();
  } else {
    const apiName = "dev-meal-api";
    const path = "/meals/prefers";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.del(apiName, path, init);
  }
}

async function deleteMyWeeklyPlan() {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(config.API_URL + "weeklyPlan", options);
    return response.json();
  } else {
    const apiName = "dev-weekly-api";
    const path = "/weeklyPlan";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.del(apiName, path, init);
  }
}

async function getProfile(id) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(config.API_URL + "users/" + id, options);
    return response.json();
  } else {
    const apiName = "dev-user-api";
    const path = `/users/${id}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function updateProfile(id, profile) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify(profile),
    };

    const response = await fetch(config.API_URL + "users/" + id, options);
    return response.json();
  } else {
    const apiName = "dev-user-api";
    const path = `/users/${id}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: profile,
    };

    return API.put(apiName, path, init);
  }
}

async function updateVoting(recipe_id, star, comment) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        star: star,
        comment: comment,
      }),
    };

    const response = await fetch(
      config.API_URL + "meals/" + recipe_id + "/vote",
      options
    );
    return response.json();
  } else {
    const apiName = "dev-meal-api";
    const path = `/meals/${recipe_id}/vote`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        star: star,
        comment: comment,
      },
    };

    return API.post(apiName, path, init);
  }
}

async function updatePrefer(recipe_id, prefer) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        preferred: prefer,
      }),
    };

    const response = await fetch(
      config.API_URL + "meals/" + recipe_id + "/prefer",
      options
    );
    return response.json();
  } else {
    const apiName = "dev-meal-api";
    const path = `/meals/${recipe_id}/prefer`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        preferred: prefer,
      },
    };

    return API.post(apiName, path, init);
  }
}

async function addWeeklyPlan(id, date, mealTime, amount) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        date: date,
        mealTime: mealTime,
        amount: amount,
      }),
    };

    const response = await fetch(config.API_URL + "weeklyPlan/" + id, options);
    return response.json();
  } else {
    const apiName = "dev-weekly-api";
    const path = `/weeklyPlan/${id}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
        amount: amount,
      },
    };

    return API.post(apiName, path, init);
  }
}

async function getWeeklyPlan(date) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        sunday: date,
      }),
    };

    const response = await fetch(config.API_URL + "weeklyPlan/list", options);
    return response.json();
  } else {
    const apiName = "dev-weekly-api";
    const path = "/weeklyPlan/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        sunday: date,
      },
    };

    return API.post(apiName, path, init);
  }
}

async function deleteWeeklyPlan(id, date, mealTime) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        startDate: date,
        mealTime: mealTime,
      }),
    };

    const response = await fetch(config.API_URL + "weeklyPlan/" + id, options);
    return response.json();
  } else {
    const apiName = "dev-weekly-api";
    const path = "/weeklyPlan/" + id;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
      },
    };

    return API.del(apiName, path, init);
  }
}

async function updateWeeklyPlan(id, date, mealTime, amount) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        startDate: date,
        mealTime: mealTime,
        amount: amount,
      }),
    };

    const response = await fetch(config.API_URL + "weeklyPlan/" + id, options);
    return response.json();
  } else {
    const apiName = "dev-weekly-api";
    const path = "/weeklyPlan/" + id;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
        amount: amount,
      },
    };

    return API.put(apiName, path, init);
  }
}

async function updateWeeklyPlanList(date, mealTime, meals) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        startDate: date,
        mealTime: mealTime,
        meals: meals,
      }),
    };

    const response = await fetch(
      config.API_URL + "weeklyPlan/updateList",
      options
    );
    return response.json();
  } else {
    const apiName = "dev-weekly-api";
    const path = "/weeklyPlan/updateList";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: {
        date: date,
        mealTime: mealTime,
        meals: meals,
      },
    };

    return API.put(apiName, path, init);
  }
}

async function getGroceries(meals) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
      body: JSON.stringify(meals),
    };

    const response = await fetch(config.API_URL + "groceries/list", options);
    return response.json();
  } else {
    const apiName = "dev-groceries-api";
    const path = "/groceries/list";
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
      body: meals,
    };

    return API.post(apiName, path, init);
  }
}

async function getGroceryTypes(language) {
  let jwtToken = store.getState().session.user?.token;
  try {
    jwtToken = "Bearer " + (await Auth.currentSession()).idToken.jwtToken;
  } catch (_) {}
  if (config.DEV_MODE) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: jwtToken,
      },
    };

    const response = await fetch(
      config.API_URL + `groceries/types?language=${language}`,
      options
    );
    return response.json();
  } else {
    const apiName = "dev-groceries-api";
    const path = `/groceries/types?language=${language}`;
    const init = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: jwtToken,
      },
    };

    return API.get(apiName, path, init);
  }
}

// =============================================
// Highly Cacheable Functions (24 hour cache)
// =============================================

const getLanguagesWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.LANGUAGES);
  return withCache(
    cacheKey,
    () => getLanguages(),
    CACHE_DURATIONS.STATIC
  );
};

const getFieldsWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.FIELDS);
  return withCache(
    cacheKey,
    () => getFields(),
    CACHE_DURATIONS.STATIC
  );
};

const getDifficultyLevelsWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.DIFFICULTY, {
    language: getLanguage()
  });
  return withCache(
    cacheKey,
    () => getDifficultyLevels(),
    CACHE_DURATIONS.STATIC
  );
};

const getCuisinesWithCache = async () => {
  const language = getLanguage();
  const cacheKey = createCacheKey(CACHE_PREFIXES.CUISINES, { language });
  return withCache(
    cacheKey,
    () => getCuisines(),
    CACHE_DURATIONS.STATIC
  );
};

const getVegetarianTypesWithCache = async () => {
  const language = getLanguage();
  const cacheKey = createCacheKey(CACHE_PREFIXES.VEG_TYPES, { language });
  return withCache(
    cacheKey,
    () => getVegetarianTypes(),
    CACHE_DURATIONS.STATIC
  );
};

const getCountriesWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.COUNTRIES);
  return withCache(
    cacheKey,
    () => getCountries(),
    CACHE_DURATIONS.STATIC
  );
};

const getCalcTypesWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.CALC_TYPES);
  return withCache(
    cacheKey,
    () => getCalcTypes(),
    CACHE_DURATIONS.STATIC
  );
};

const getGroceryTypesWithCache = async (language) => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.GROCERY, { language });
  return withCache(
    cacheKey,
    () => getGroceryTypes(language),
    CACHE_DURATIONS.STATIC
  );
};

const getMealLevelsWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.MEAL_LEVELS, {
    language: getLanguage()
  });
  return withCache(
    cacheKey,
    () => getMealLevels(),
    CACHE_DURATIONS.STATIC
  );
};

// =============================================
// Moderately Cacheable Functions (1 hour cache)
// =============================================

const getRDDEvaluationsWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.RDD);
  return withCache(
    cacheKey,
    () => getRDDEvaluations(),
    CACHE_DURATIONS.MODERATE
  );
};

const getContentsWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.CONTENTS);
  return withCache(
    cacheKey,
    () => getContents(),
    CACHE_DURATIONS.MODERATE
  );
};

const getIngredientsWithCache = async (page, count, sortField, sortBy, keyword) => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.INGREDIENTS, {
    page,
    count,
    sortField,
    sortBy,
    keyword
  });
  return withCache(
    cacheKey,
    () => getIngredients(page, count, sortField, sortBy, keyword),
    CACHE_DURATIONS.MODERATE
  );
};

// =============================================
// Short-term Cache Functions (1 minute cache)
// =============================================

const getPrefersWithCache = async (page, count, sortBy, filter) => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.PREFERS, {
    page,
    count,
    sortBy,
    ...filter
  });
  return withCache(
    cacheKey,
    () => getPrefers(page, count, sortBy, filter),
    CACHE_DURATIONS.DYNAMIC
  );
};

const getWeeklyPlanWithCache = async (date) => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.WEEKLY, { date });
  return withCache(
    cacheKey,
    () => getWeeklyPlan(date),
    CACHE_DURATIONS.DYNAMIC
  );
};

const getRecommendationsWithCache = async () => {
  const cacheKey = createCacheKey(CACHE_PREFIXES.RECOMMENDATIONS);
  return withCache(
    cacheKey,
    () => getRecommendations(),
    CACHE_DURATIONS.DYNAMIC
  );
};

// =============================================
// Cache Clearing Functions
// =============================================

// Function to clear specific cache entries
const clearCacheByPrefix = (prefix) => {
  const allKeys = Object.keys(localStorage);
  allKeys
    .filter(key => key.startsWith(prefix))
    .forEach(key => localStorage.removeItem(key));
};

// Enhanced update functions that clear relevant caches
const updatePreferWithCache = async (recipe_id, prefer) => {
  const result = await updatePrefer(recipe_id, prefer);
  // Clear preferences and recommendations cache after update
  clearCacheByPrefix(CACHE_PREFIXES.PREFERS);
  clearCacheByPrefix(CACHE_PREFIXES.RECIPE + recipe_id);
  clearCacheByPrefix(CACHE_PREFIXES.RECOMMENDATIONS);
  return result;
};

const updateVotingWithCache = async (recipe_id, star, comment) => {
  const result = await updateVoting(recipe_id, star, comment);
  // Clear recipe cache and recommendations after voting
  clearCacheByPrefix(CACHE_PREFIXES.RECIPE);
  clearCacheByPrefix(CACHE_PREFIXES.RECOMMENDATIONS);
  return result;
};

const updateWeeklyPlanWithCache = async (id, date, mealTime, amount) => {
  const result = await updateWeeklyPlan(id, date, mealTime, amount);
  // Clear weekly plan cache after update
  clearCacheByPrefix(CACHE_PREFIXES.WEEKLY);
  return result;
};

const addWeeklyPlanWithCache = async (id, date, mealTime, amount) => {
  const result = await addWeeklyPlan(id, date, mealTime, amount);
  // Clear weekly plan cache after adding a meal
  clearCacheByPrefix(CACHE_PREFIXES.WEEKLY);
  return result;
};

const deleteWeeklyPlanWithCache = async (id, date, mealTime) => {
  const result = await deleteWeeklyPlan(id, date, mealTime);
  // Clear weekly plan cache after deletion
  clearCacheByPrefix(CACHE_PREFIXES.WEEKLY);
  return result;
};

const deleteMyWeeklyPlanWithCache = async () => {
  const result = await deleteMyWeeklyPlan();
  // Clear weekly plan cache after clearing all plans
  clearCacheByPrefix(CACHE_PREFIXES.WEEKLY);
  return result;
};

const updateWeeklyPlanListWithCache = async (date, mealTime, meals) => {
  const result = await updateWeeklyPlanList(date, mealTime, meals);
  // Clear weekly plan cache after updating the list
  clearCacheByPrefix(CACHE_PREFIXES.WEEKLY);
  return result;
};


export const ApiService = {
  // Highly cacheable endpoints
  getLanguages: getLanguagesWithCache,
  getFields: getFieldsWithCache,
  getDifficultyLevels: getDifficultyLevelsWithCache,
  getCuisines: getCuisinesWithCache,
  getVegetarianTypes: getVegetarianTypesWithCache,
  getCountries: getCountriesWithCache,
  getCalcTypes: getCalcTypesWithCache,
  getGroceryTypes: getGroceryTypesWithCache,
  getMealLevels: getMealLevelsWithCache,

  // Moderately cacheable endpoints
  getRDDEvaluations: getRDDEvaluationsWithCache,
  getContents: getContentsWithCache,
  getIngredients: getIngredientsWithCache,

  // Short-term cache endpoints
  getPrefers: getPrefersWithCache,
  getWeeklyPlan: getWeeklyPlanWithCache,
  getRecommendations: getRecommendationsWithCache,

  // Existing recipe endpoints (keeping current implementation)
  getRecipe: getRecipeWithCache,
  getRecipes: getRecipesWithCache,

  // Update operations with cache clearing
  updatePrefer: updatePreferWithCache,
  updateVoting: updateVotingWithCache,
  updateWeeklyPlan: updateWeeklyPlanWithCache,
  addWeeklyPlan: addWeeklyPlanWithCache,
  deleteWeeklyPlan: deleteWeeklyPlanWithCache,
  deleteMyWeeklyPlan: deleteMyWeeklyPlanWithCache,
  updateWeeklyPlanList: updateWeeklyPlanListWithCache,

  // Remaining unchanged endpoints
  getIngredient,
  getRDD,
  getRDDs,
  deleteMyList,
  getProfile,
  updateProfile,
  getGroceries
};
